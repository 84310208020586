<template>
  <div>
    <h3>Create Organisation</h3>
    <div>
      <form name="abn" v-on:submit.prevent>
        <lookup
          v-on:create-org="setShowNameField"
          v-on:duplicate-orgs="setDuplicateOrgs"
          v-on:reset-lookup="onResetLookup"
          :org="org"
          :label="label"
          :edit-org="false"
        ></lookup>

        <div class="duplicate-abn" v-if="showDuplicateOrgs">
          <div class="alert alert-warning">
            <h6>This {{ abnOrNzbn }} is already registered with SmartyFile.</h6>
            You can request to join an existing organisation (listed below) or
            you can create another organisation using the same
            {{ abnOrNzbn }} (note: if you create a new organisation you will not
            be able to share information with any other organisations).
          </div>
          <b-card class="mb-3">
            <duplicate-orgs :orgs="duplicateOrgs"></duplicate-orgs>
          </b-card>
        </div>

        <div
          class="create-org"
          v-if="showNameField"
          :class="{card: showDuplicateOrgs}"
        >
          <org-create-create
            :class="{'card-body': showDuplicateOrgs}"
            :show-name-field="showNameField"
            :org="org"
            :is-unique="!showDuplicateOrgs"
          ></org-create-create>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Lookup from "@/components/org/Lookup";
import OrgCreateCreate from "@/components/org/OrgCreateCreate";
import DuplicateOrgs from "@/components/org/DuplicateOrgs";

export default {
  name: "OrgCreate",
  components: {OrgCreateCreate, Lookup, DuplicateOrgs},
  data() {
    return {
      showNameField: false,
      org: {
        abn: null,
        nzbn: null,
      },
      label:
        "Please enter the business registration number (ABN/NZBN) of the organisation you wish to register",
      duplicateOrgs: [],
    };
  },
  computed: {
    abnOrNzbn() {
      return this.org.abn ? "ABN" : "NZBN";
    },
    showDuplicateOrgs() {
      return this.duplicateOrgs.length > 0;
    },
  },
  methods: {
    setShowNameField(value, abn, nzbn) {
      this.showNameField = value;
      this.org.abn = abn;
      this.org.nzbn = nzbn;
    },
    setDuplicateOrgs(orgs) {
      this.duplicateOrgs = orgs;
    },
    onResetLookup() {
      this.showNameField = false;
      this.duplicateOrgs = [];
    },
  },
};
</script>

<style scoped></style>
