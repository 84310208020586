<template>
  <div class="h-100">
    <div>
      <h6>
        Join existing organisation
        <span class="thin">{{ orgName }}</span>
      </h6>
      <label class="thin">Message to administrator</label>
      <b-form-group>
        <b-form-textarea
          id="message"
          max-rows="2"
          v-model="$v.message.$model"
          aria-describedby="counter"
          :formatter="formatMessage"
          :state="isMessageValid"
        />
        <b-form-invalid-feedback :state="isMessageValid">
          Please enter message to administrators.
        </b-form-invalid-feedback>
        <b-form-text id="counter">{{ message.length }} / 200</b-form-text>
      </b-form-group>
      <div class="mb-3">
        <p>
          Request permission from existing account administrators to join the
          organisation.
        </p>

        <p>
          <span class="label-bold">Important notes:</span> By clicking ‘Join’, a
          message containing your full name and email address will be sent to
          the administrator of the organisation.
        </p>
      </div>
      <b-button variant="primary" @click="join(org.id)"> Join </b-button>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import handleApiError from "@/shared/apiErrorUtil";
import {mapActions} from "vuex";

export default {
  name: "OrgJoin",
  props: ["org"],
  data() {
    return {
      message: "",
    };
  },
  computed: {
    orgName() {
      return this.org.name;
    },
    isMessageValid() {
      return !this.$v.message.$dirty || !this.$v.message.$invalid
        ? null
        : false;
    },
  },
  validations: {
    message: {
      required,
    },
  },
  methods: {
    ...mapActions("orgStore", ["joinOrg"]),
    formatMessage(input) {
      const max = 200;
      return input.length > max ? input.substring(0, max) : input;
    },
    join(orgId) {
      this.$v.$touch();
      if (this.$v.message.$invalid) {
        return;
      }
      const joinRequest = {
        org: orgId,
        message: this.message,
        orgName: this.orgName,
      };
      return this.joinOrg(joinRequest)
        .then(() => {
          this.$bvToast.toast(
            "Your request to join the organisation has been sent.",
            {
              title: "Request sent",
              autoHideDelay: 3000,
            }
          );
          this.$router.push("/organisation");
        })
        .catch(error => {
          console.log("Error requesting to join org " + orgId + ": " + error);
          handleApiError(error, this);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.thin {
  font-weight: 400;
}
</style>
