<template>
  <div class="pb-2">
    <h6 v-if="heading === 'h6'" class="mb-0">{{ label }}</h6>
    <label v-else class="label-bold mb-0">{{ label }}</label>
    <div>
      <slot>{{ value }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoLine",
  props: ["label", "value", "heading"],
};
</script>
