<template>
  <div role="tablist">
    <h4>Option 1: Join an existing organisation</h4>
    <p>You can request to join an existing organsiation.</p>
    <div v-for="(org, index) in sortedOrgs" v-bind:key="org.id">
      <b-card class="mb-1">
        <div role="tab">
          <b-link
            v-b-toggle="'orgExpand' + index"
            :disabled="org.alreadyMember || org.alreadySentJoinRequest"
          >
            <b-icon-triangle-fill
              rotate="90"
              v-if="!org.alreadyMember && !org.alreadySentJoinRequest"
              class="closed"
            ></b-icon-triangle-fill>
            <b-icon-triangle-fill
              rotate="180"
              v-if="!org.alreadyMember && !org.alreadySentJoinRequest"
              class="open"
            ></b-icon-triangle-fill>
            {{ org.name }}
          </b-link>
          <span v-if="org.alreadyMember" class="join-disabled"
            >(Already a member)</span
          >
          <span v-if="org.alreadySentJoinRequest" class="join-disabled"
            >(Already sent join request)</span
          >
        </div>
        <b-collapse
          :id="'orgExpand' + index"
          accordion="orgExpand"
          role="tabpanel"
        >
          <hr />
          <org-join :org="org"></org-join>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import OrgJoin from "./OrgJoin";
import {mapActions, mapGetters} from "vuex";
import handleApiError from "@/shared/apiErrorUtil";

export default {
  name: "DuplicateOrgs",
  components: {OrgJoin},
  props: ["orgs"],
  data() {
    return {
      sortedOrgs: [],
      joinRequestResults: [],
    };
  },
  methods: {
    ...mapGetters("orgStore", ["getOrgById"]),
    ...mapActions("joinRequestStore", ["fetchJoinRequestsByUser"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    sortOrgs(orgs) {
      let alreadyMemberOrgs = [];
      let alreadySentJoinReqOrgs = [];
      let canJoinOrgs = [];

      orgs.map(org => {
        if (this.getOrgById()(org.id)) {
          alreadyMemberOrgs.push({
            ...org,
            alreadyMember: true,
          });
        } else {
          if (
            this.joinRequestResults.filter(r => r.orgId === org.id).length > 0
          ) {
            alreadySentJoinReqOrgs.push({
              ...org,
              alreadySentJoinRequest: true,
            });
          } else {
            canJoinOrgs.push(org);
          }
        }
      });

      const sortFn = (a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      };

      alreadyMemberOrgs.sort(sortFn);
      alreadySentJoinReqOrgs.sort(sortFn);
      canJoinOrgs.sort(sortFn);

      return canJoinOrgs.concat(alreadySentJoinReqOrgs, alreadyMemberOrgs);
    },
  },
  mounted() {
    this.fetchJoinRequestsByUser(this.getCurrentUser().id)
      .then(response => {
        this.joinRequestResults.splice(
          0,
          this.joinRequestResults.length,
          ...response.data.content
        );
        this.sortedOrgs = this.sortOrgs(this.orgs);
      })
      .catch(error => {
        handleApiError(error, this);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/bootstrap-variables";

.card-body {
  padding: 0.5em 1em;
}

.btn-link:hover {
  color: white;
  background-color: $primary;
}
.btn-link:focus {
  box-shadow: none;
}

.card-body {
  padding: 0.5em 1em;
}

.collapsed > .open,
.not-collapsed > .closed {
  display: none;
}

a.disabled {
  pointer-events: none;
  color: gray;
}

.join-disabled {
  color: gray;
}
</style>
