<template>
  <div>
    <h4 v-if="!isUnique">Option 2: Create organisation</h4>
    <p v-if="!isUnique">You can create another organisation.</p>
    <b-form-group
      v-if="showNameField"
      label="Please enter a name for your organisation:"
      label-for="name"
    >
      <b-form-input
        id="name"
        v-model="orgName"
        v-on:keyup.enter="create"
        :state="isNameValid()"
        @input="$v.orgName.$touch()"
      />
      <b-form-invalid-feedback :state="isNameValid()">
        The organisation name must be 1-100 characters long.
      </b-form-invalid-feedback>
    </b-form-group>
    <div v-if="showNameField">
      <p>
        <span class="label-bold">Important notes:</span> By clicking ‘Create’,
        you will be the administrator of this organisation. As administrator,
        you will have authority to manage the organisational profile, invite
        additional users and manage their permission levels. For further
        information on managing your organisation and users, please visit our
        <a
          href="http://applicanthelp.smartygrants.com.au/smartyfile/"
          target="_blank"
          rel="noopener noreferrer"
          >Help page</a
        >.
      </p>
    </div>
    <b-button v-if="showNameField" v-on:click="create" variant="primary">
      Create
    </b-button>
  </div>
</template>

<script>
import {maxLength, required} from "vuelidate/lib/validators";
import handleApiError from "../../shared/apiErrorUtil";
import {mapActions} from "vuex";

export default {
  name: "OrgCreateCreate",
  props: ["org", "showNameField", "isUnique"],
  data() {
    return {
      orgName: "",
    };
  },
  validations: {
    orgName: {
      required,
      maxLength: maxLength(100),
    },
  },

  methods: {
    isNameValid() {
      const {$dirty, $error} = this.$v.orgName;
      return $dirty ? !$error : null;
    },
    create() {
      this.$v.$touch();
      if (this.$v.orgName.$invalid) {
        return;
      }
      const currentOrg = {
        abn: this.org.abn,
        nzbn: this.org.nzbn,
        name: this.orgName,
      };

      // TODO Consider displaying some visual indication that the request is being processed
      this.saveOrg(currentOrg)
        .then(result => {
          this.loadUserDetails()
            .then(() => {
              this.$router.push({
                name: "OrgDetails",
                params: {
                  organisationId: result.id,
                  editContacts: true,
                  editMission: true,
                  newOrg: true,
                },
              });
            })
            .catch(error => {
              handleApiError(error, this);
            });
        })
        .catch(error => {
          handleApiError(error, this);
        });
    },
    ...mapActions("orgStore", ["saveOrg"]),
    ...mapActions("auth", ["loadUserDetails"]),
  },
};
</script>

<style scoped></style>
